<template>
  <div>
    <div class="title-page">
      <router-link to="/payments" class="invoice__link">
        Payments
      </router-link>
      <icon-arrow color="#1E70BB" />
      Invoice {{ invoiceId }}
    </div>

    <VLoader v-if="_fetchingStatus === requestStatus.PENDING" />
    <div v-else-if="_fetchingStatus === requestStatus.SUCCESS" class="invoice__main">
      <div class="invoice__main-row">
        <RequestsMainData />
        <RequestsBilling />
      </div>
      <RequestsDetails class="invoice__details" />
      <RequestsPayments />
    </div>
  </div>
</template>

<script>
import IconArrow from '@/js/components/icons/IconArrow';
import RequestsBilling from '@/js/components/pages/payments/RequestsDetails/RequestsBilling';
import RequestsDetails from '@/js/components/pages/payments/RequestsDetails/RequestsDetails';
import RequestsMainData from '@/js/components/pages/payments/RequestsDetails/RequestsMainData';
import RequestsPayments from '@/js/components/pages/payments/RequestsDetails/RequestsPayments';
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "PaymentRequestsDetails",
  components: { RequestsPayments, RequestsDetails, RequestsBilling, RequestsMainData, IconArrow },
  data() {
    return {
      requestStatus,
      invoiceId: +this.$route.params.id || null,
    };
  },
  computed: {
    _fetchingStatus() {
      return this.$store.state.paymentRequestsDetails.fetchingStatus;
    },
  },
  created() {
    this.$store.commit('paymentRequestsDetails/SET_INVOICE_ID', this.invoiceId);
  },
  mounted() {
    this.getInvoiceDetails();
  },
  methods: {
    async getInvoiceDetails() {
      await this.$store.dispatch('paymentRequestsDetails/getInvoiceDetails');
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice {
  &__link {
    text-decoration: none;
    color: var(--blue-main);
  }

  &__main-row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(510px, 1fr));
    grid-gap: 24px;
    align-items: flex-start;
    margin-bottom: 24px;

    @include media("max", "xl") {
      grid-template-columns: repeat(2, minmax(420px, 1fr));
      grid-gap: 16px;
      margin-bottom: 16px;
    }
  }

  &__details {
    margin-bottom: 24px;

    @include media("max", "xl") {
      margin-bottom: 16px;
    }
  }
}
</style>