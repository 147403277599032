<template>
  <VBox>
    <VCollapse>
      <template #header>
        Details
      </template>
      <template #body>
        <VTable
          :headCols="headCols"
          :bodyRows="_bodyRows"
        >
          <template #col-1>
            {{ _invoiceDetails.start_date }}
          </template>
          <template #col-2>
            {{ _invoiceDetails.end_date }}
          </template>
          <template #col-3="{row}">
            <router-link v-if="row.offer_name" :to="`/offers/${row.offer_id}`">
              {{ row.offer_name }}
            </router-link>
            <span v-else>Adjustment</span>
          </template>
          <template #col-4="{row}">
            {{ $store.getters['auth/currencySign'] }} {{ row.currency }}
          </template>
          <template #col-5="{row}">
            {{ $store.getters['auth/currencySign'] }} {{ row.amount }}
          </template>
          <template #col-6="{row}">
            <VTextArea
              readonly
              :value="row.notes"
            />
          </template>
        </VTable>
      </template>
    </VCollapse>
  </VBox>
</template>

<script>
import VBox from '@/js/components/common/VBox';
import VCollapse from '@/js/components/common/VCollapse';
import VTable from '@/js/components/common/VTable';
import VTextArea from '@/js/components/common/VTextArea';

export default {
  name: "RequestsDetails",
  components: { VTextArea, VTable, VCollapse, VBox },
  data: () => ({
    headCols: [
      {
        id: 0,
        slug: 'id',
        name: 'ID',
        sort: false,
        orderBy: 'def'
      },
      {
        id: 1,
        slug: 'from',
        name: 'From',
        sort: false,
        orderBy: 'def'
      },
      {
        id: 2,
        slug: 'to',
        name: 'To',
        sort: false,
        orderBy: 'def'
      },
      {
        id: 3,
        slug: 'offer_name',
        name: 'Name',
        sort: false,
        orderBy: 'def'
      },
      {
        id: 4,
        slug: 'currency',
        name: 'Currency',
        sort: false,
        orderBy: 'def'
      },
      {
        id: 5,
        slug: 'amount',
        name: 'Amount',
        sort: false,
        orderBy: 'def'
      },
      {
        id: 6,
        slug: 'notes',
        name: 'Notes',
        sort: false,
        orderBy: 'def'
      },
    ]
  }),
  computed: {
    _bodyRows() {
      return this.$store.getters['paymentRequestsDetails/getDetails'].map((invoice) => ({
        id: invoice.network_affiliate_invoice_detail_id,
        from: invoice.time_created,
        to: invoice.time_saved,
        offer_name: invoice.network_offer_name || null,
        offer_id: invoice.network_offer_id,
        currency: invoice.currency_id,
        amount: parseFloat(invoice.amount).toFixed(2),
        notes: invoice.notes,
      }));
    },
    _invoiceDetails() {
      return this.$store.state.paymentRequestsDetails.details;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>