<template>
  <VBox>
    <VCollapse>
      <template #header>
        Payments
      </template>
      <template #body>
        <VTable
          :headCols="headCols"
          :bodyRows="_bodyRows"
        >
          <template #col-3="{row}">
            {{ $store.getters['auth/currencySign'] }} {{ row.amount }}
          </template>
          <template #col-4="{row}">
            <VTextArea
              readonly
              :value="row.notes"
            />
          </template>
        </VTable>
      </template>
    </VCollapse>
  </VBox>
</template>

<script>
import VBox from '@/js/components/common/VBox';
import VCollapse from '@/js/components/common/VCollapse';
import VTable from '@/js/components/common/VTable';
import VTextArea from '@/js/components/common/VTextArea';

export default {
  name: "RequestsPayments",
  components: { VTextArea, VTable, VCollapse, VBox },
  data: () => ({
    headCols: [
      {
        id: 0,
        slug: 'status',
        name: 'Status',
        sort: false,
        orderBy: 'def'
      },
      {
        id: 1,
        slug: 'payment_type',
        name: 'Payment method',
        sort: false,
        orderBy: 'def'
      },
      {
        id: 2,
        slug: 'currency',
        name: 'Currency',
        sort: false,
        orderBy: 'def'
      },
      {
        id: 3,
        slug: 'amount',
        name: 'Amount',
        sort: false,
        orderBy: 'def'
      },
      {
        id: 4,
        slug: 'notes',
        name: 'Notes',
        sort: false,
        orderBy: 'def'
      },
    ]
  }),
  computed: {
    _bodyRows() {
      return this.$store.getters['paymentRequestsDetails/getPayments'].map((invoice) => ({
        status: invoice.status,
        payment_type: invoice.payment_type,
        currency: invoice.currency_id || '-',
        amount: parseFloat(invoice.amount).toFixed(2),
        notes: invoice.notes,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>

</style>