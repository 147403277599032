var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VBox',[_c('VCollapse',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Details ")]},proxy:true},{key:"body",fn:function(){return [_c('VTable',{attrs:{"headCols":_vm.headCols,"bodyRows":_vm._bodyRows},scopedSlots:_vm._u([{key:"col-1",fn:function(){return [_vm._v(" "+_vm._s(_vm._invoiceDetails.start_date)+" ")]},proxy:true},{key:"col-2",fn:function(){return [_vm._v(" "+_vm._s(_vm._invoiceDetails.end_date)+" ")]},proxy:true},{key:"col-3",fn:function(ref){
var row = ref.row;
return [(row.offer_name)?_c('router-link',{attrs:{"to":("/offers/" + (row.offer_id))}},[_vm._v(" "+_vm._s(row.offer_name)+" ")]):_c('span',[_vm._v("Adjustment")])]}},{key:"col-4",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$store.getters['auth/currencySign'])+" "+_vm._s(row.currency)+" ")]}},{key:"col-5",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$store.getters['auth/currencySign'])+" "+_vm._s(row.amount)+" ")]}},{key:"col-6",fn:function(ref){
var row = ref.row;
return [_c('VTextArea',{attrs:{"readonly":"","value":row.notes}})]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }