<template>
  <VBox class="request-billing">
    <VOverlay v-if="_fetchingStatus === requestStatus.PENDING">
      <VLoader />
    </VOverlay>

    <VCollapse>
      <template #header>
        Billing
      </template>
      <template #body>
        <div class="text-semibold mb-1">Billing frequency</div>
        <div class="color-grey-main text-capitalize">
          {{ _billingFrequency }}
        </div>
      </template>
    </VCollapse>
  </VBox>
</template>

<script>
import VBox from '@/js/components/common/VBox';
import VCollapse from '@/js/components/common/VCollapse';
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "RequestsBilling",
  components: { VCollapse, VBox },
  data: () => ({
    requestStatus,
  }),
  computed: {
    _fetchingStatus() {
      return this.$store.state.paymentRequests.fetchingStatusSummary;
    },
    _invoiceDetails() {
      return this.$store.state.paymentRequests.details;
    },
    _billingFrequency() {
      return this._invoiceDetails?.billing?.billing_frequency || '-';
    }
  },
  mounted() {
    if (!Object.keys(this._invoiceDetails).length) this.getInvoiceSummary();
  },
  methods: {
    async getInvoiceSummary() {
      await this.$store.dispatch('paymentRequests/getInvoiceSummary');
    }
  },
};
</script>

<style lang="scss" scoped>
.request-billing {
  position: relative;
}
</style>