<template>
  <VBox class="main-data">
    <VOverlay v-if="_updatingStatus === requestStatus.PENDING">
      <VLoader />
    </VOverlay>

    <VCollapse>
      <template #header>
        Main Data
      </template>
      <template #body>
        <v-button
          type="small-blue"
          class="main-data__btn-export btn-xl"
          @click="exportInvoice(_invoiceDetails.id)"
        >
          Export PDF
        </v-button>
        <div
          v-for="col in columnsLabels"
          :key="col.slug"
          class="main-data__row"
        >
          <div class="text-semibold">{{ col.label }}</div>
          <div>{{ _invoiceDetails[col.slug] || '-' }}</div>
        </div>
        <div>
          <VTextArea
            label="Notes"
            size="lg"
            readonly
            :value="_invoiceDetails.notes || 'N/A'"
          />
        </div>
      </template>
    </VCollapse>
  </VBox>
</template>

<script>
import VBox from '@/js/components/common/VBox';
import VCollapse from '@/js/components/common/VCollapse';
import VTextArea from '@/js/components/common/VTextArea';
import InvoiceMixin from '@/js/mixins/invoiceMixin';
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "RequestsMainData",
  components: { VTextArea, VCollapse, VBox },
  mixins: [InvoiceMixin],
  data: () => ({
    requestStatus,
    columnsLabels: [
      { slug: 'id', label: 'ID' },
      { slug: 'start_date', label: 'Start date' },
      { slug: 'time_created', label: 'Date created' },
      { slug: 'end_date', label: 'End date' },
      { slug: 'time_saved', label: 'Last modification' },
      { slug: 'status', label: 'Status' },
    ],
  }),
  computed: {
    _invoiceDetails() {
      return this.$store.state.paymentRequestsDetails.details;
    },
    _updatingStatus() {
      return this.$store.state.paymentRequests.updatingStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-data {
  position: relative;

  &__btn-export {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__row {
    display: grid;
    grid-template-columns: 130px 1fr;
    grid-column-gap: 60px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>