<template>
  <div
    class="v-textarea"
    :class="[
      size ? `v-textarea--${size}` : '',
      {'v-textarea--full-width': fullWidth}
    ]"
  >
    <label>
      <span class="v-textarea__label mb-1">{{ label }}</span>
      <textarea
        class="v-textarea__field"
        :class="{
          'v-textarea__field--error': error,
          'v-textarea__field--readonly': readonly,
        }"
        :readonly="readonly"
        :value="value"
        @input="$emit('input', $event.target.value)"
      ></textarea>
    </label>
  </div>
</template>

<script>
export default {
  name: "VTextArea",
  props: {
    label: String,
    value: String,
    error: Boolean,
    size: {
      type: String,
      default: 'md',
    },
    fullWidth: Boolean,
    readonly: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.v-textarea {
  width: 100%;

  &--md {
    max-width: 260px;
  }

  &--lg {
    max-width: 320px;
  }

  &--full-width {
    max-width: 100%;
  }

  &__label {
    display: block;
    font-weight: 600;
  }

  &__field {
    width: 100%;
    height: 40px;
    padding: 8px 12px;
    font-family: var(--OpenSans);
    border: 1px solid var(--grey-light-1);
    border-radius: 8px;
    resize: none;

    &:focus {
      outline: none;
      border-color: var(--blue-main);
    }

    &--readonly {
      &:focus {
        border-color: var(--grey-light-1);
      }
    }

    &--error {
      border-color: var(--red-main);
    }
  }
}
</style>